import Footer from "../Components/Footer";
import Header from "../Components/Header";
import HeroHome from "../Components/HeroHome";
import AboutSection from "../Components/AboutSection";
import Mission from "../Components/Mission";
import ExtraSection from "../Components/ExtraSection";
// import DonateNow from "../Components/Donate";

export default function Home() {
  return (
    <div>
      <Header />
      <main id="main" className="main min-height">
        <HeroHome />
        {/* <DonateNow /> */}
        <Mission />
        <AboutSection />
        <ExtraSection />
      </main>
      <Footer />
    </div>
  );
}
