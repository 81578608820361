import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SpecialQuotes from "../Components/SpecialQuotes";

export default function Message() {
  return (
    <div>
      <Header />
      <main id="main" className="main min-height">
        <section className="px-3">
          <div className="w-full border-b mb-5 pt-6">
            <div className="mx-auto px-8">
              <div className="mb-6 text-2xl font-bold text-[#333333]">
                <span>Sandesh</span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex relative justify-center items-center w-full z-10 mb-3">
              <img
                className="outline-offset-4 outline-dotted"
                src={require("../assets/jkp sandesh.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className="flex-1 bg-message bg-center bg-no-repeat bg-cover">
            <div className="py-10 px-5 md:px-40">
              <h2 className="leading-normal text-heads text-3xl text-center font-semibold">
                विजयते श्रीकृष्ण-संकीर्तनम
              </h2>
              <p className="py-5 text-lg">प्रिय साधको,</p>
              <p className="text-lg">
                कलिकाल में नाम धन ही सबसे बड़ा धन (साधना) है। कलिकाल में यही एक
                मात्र कल्याण का मार्ग शास्त्रों-वेदो में बताया गया है।
              </p>
              <SpecialQuotes
                nosvg
                data={{
                  stanza1: "हरेर्नाम हरेर्नाम हरेर्नामैव केवलं।",
                  stanza2: "कलौ नास्त्यैव नास्त्यैव नास्त्यैव गतिरन्यथा।।",
                  writer: "- बृहन्नार्दीय पुराण",
                }}
              />
              <p className="text-lg">
                कलियुग में केवल हरिनाम, हरिनाम और हरिनाम से ही उद्धार हो सकता
                है| हरिनाम के अलावा कलियुग में उद्धार का अन्य कोई भी उपाय नहीं
                है! नहीं है! नहीं है!
              </p>
              <SpecialQuotes
                nosvg
                data={{
                  stanza1: "कलयुग केवल नाम अधारा, सुमिर सुमिर नर उतरहि पारा।",
                  writer: "- रामायण",
                }}
              />
              <p className="py-3 text-lg">
                इसलिए आप सभी से विनम्र प्रार्थना है की दिन में केबल 1 घंटे का
                समय प्रत्येक व्यक्ति नाम धन कमाने में लगायें। इसमें में आपके सदा
                साथ हूँ।
              </p>
              <p className="py-3 text-lg">
                कृपया आप दिन में 1 घंटे का समय देने का कष्ट करें तो मेरे ऊपर
                आपकी बड़ी कृपा होगी और में आपकी इस कृपा का सदा आभारी रहूँगा।{" "}
              </p>
              <SpecialQuotes
                nosvg
                data={{
                  stanza1: "ब्रह्म-राम में नाम बड़, बरदायक बरदानी।",
                  stanza2: "रामचरित सत कोटि मह, लिये महेश जीय जानी ।।",
                }}
              />

              <p className="text-end text-2xl">
                नाम धन भिखारी <br />- पं. महेश दास
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
