import Footer from "../Components/Footer";
import Header from "../Components/Header";
import ThoughtSection from "../Components/ThoughtSection";

export default function DivineThoughts() {
  return (
    <div>
      <Header />
      <main id="main" className="main min-height">
        <section>
          <div className="w-full border-b mb-5 pt-6">
            <div className="mx-auto px-8">
              <div className="mb-6 text-2xl font-bold text-[#333333]">
                <span>5 Divine Thoughts</span>
              </div>
            </div>
          </div>
          <div className="italic mx-3 bg-cardBackground rounded-lg p-3 md:p-10 outline-offset-4 outline-dotted">
            <h1 className="text-2xl text-center">
              मनुष्य जीवन में ५ बातें जरुरी हैं। <br /> (5 things are necessary
              in human life.)
            </h1>
            <div className="text-lg my-3 block md:flex justify-between">
              <span>१. नियम (Rules)</span>
              <br />
              <span> २. कृपा (Grace)</span>
              <br />
              <span>३.तत्त्व ज्ञान (Philosophy)</span>
              <br />
              <span>४. सेवा (Service) </span>
              <br />
              <span>५. तीर्थ (Pilgrimage)</span>
            </div>
          </div>
          <div className="italic mx-0 md:mx-3 rounded-lg p-3 md:p-10">
            <ThoughtSection
              subheading="१. नियम (Rules)"
              paraHtml={`1. हमारे गुरुदेव जगद्गुरु श्री कृपालु जी महाराज है। <br />
            2. सच्चिदानंद श्रीकृष्ण ही हमारे एकमात्र ईश्वर है। <br />
            3. हमारा जप मंत्र "राधे" नाम है।
            <br />
            4. हमारा संकीर्तन मंत्र- "कृष्ण" नाम महामंत्र है। <br />
            5. हमारे ग्रंथ गीता-रामायण है।
            <br />`}
              img={require("../assets/ramayan.jpg")}
              img2={require("../assets/geeta.jpg")}
            />
            <ThoughtSection
              subheading="२. कृपा (Graces)"
              paraHtml={`<p className="my-3">कल्याण के लिए ५ कृपा जरुरी हैं।</p>
            १. मानव जन्म <br />
            २. सदगुरु का मिलना <br />
            ३. साधना भक्ति द्वारा शरणागति <br />
            ४. कृपा द्वारा भगवद दर्शन <br />
            ५. प्रेम और सेवा (सिद्धा भक्ति) का प्राप्त होना <br />`}
              position="start"
              img2={require("../assets/dada guru.jpg")}
              img={require("../assets/kripalu ji.jpg")}
            />
            <ThoughtSection
              subheading="३.तत्त्व ज्ञान (Philosophy)"
              paraHtml={`<p className="my-3">
            सदगुरु से ५ तत्त्वों का तत्त्व ज्ञान जरुरी है जिसे शास्त्रों
            में अर्थपंचक सिद्धांत के नाम से जाना जाता है।
          </p>
          १. माया क्या है <br />
          २. ज्ञान क्या है <br />
          ३. वैराग्य क्या है <br />
          ४. ईश्वर जीव में क्या भेद है <br />
          ५. भक्ति क्या है
          <br />`}
              img2={require("../assets/bhagwat.jpg")}
              img={require("../assets/kripalu ji lecture.jpg")}
            />
            <ThoughtSection
              subheading="४. सेवा (Service)"
              paraHtml={`<p className="my-3">
              अगर हो सके तो जीवन में ५ सेवा जरूर करनी चाहियें ।
            </p>
            १. माता पिता और गुरु की सेवा <br />
            २. पौधे लगाकर भूमि की सेवा <br />
            ३. गऊ सेवा <br />
            ४. कन्या सेवा (शिक्षा और शादी सहयोग द्वारा )<br />
            ५. साधु -संत और ब्रह्मणो की सेवा
            <br />`}
              position="start"
              img={require("../assets/beti bachao.jpg")}
              img2={require("../assets/gau seva.jpg")}
            />
            <ThoughtSection
              subheading="५. तीर्थ (Pilgrimage)"
              paraHtml={`<p className="my-3">
              अगर हो सके तो जीवन में ५ तीर्थ जरूर करने चाहियें ।
            </p>
            १. गुरु धाम <br />
            २. ब्रज धाम <br />
            ३. अयोध्या धाम <br />
            ४. काशी धाम <br />
            ५. हरिद्वार गंगा धाम <br />`}
              img2={require("../assets/maheshdas_ganga.jpg")}
              img={require("../assets/vrindavan.jpg")}
            />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
