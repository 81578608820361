import { Link } from "react-router-dom";
import IMG1 from "../assets/about_img.jpg";

export default function AboutSection() {
  return (
    <section className="my-4 px-8">
      <div className="flex flex-wrap-reverse md:flex-wrap justify-between">
        <div className="flex-1 pr-0 md:pr-10 text-justify flex flex-col justify-around">
          <div>
            <h1 className="text-xl md:text-3xl my-3 text-center">
              <span className="italic font-bold font-Philosopher">
                पं. महेश दास&nbsp;
              </span>
              <span className="font-bold font-Philosopher">
                ( Pt. Mahesh Das )
              </span>
            </h1>
            <p className="font-Devnagri italic">
              दास का जन्म उत्तरप्रदेश के जिला हापुड़ के ग्राम शिव नगरी बागड़पुर
              में एक ब्राह्मण परिवार में दिन रविवार १४ जून १९८७ को प्रातःकाल
              हुआ। दास की माता का नाम ओमवती देवी तथा पिता का नाम श्री देवेन्द्र
              प्रशाद शर्मा है। दास की प्रारंभिक शिक्षा अपने ग्राम में ही सरकारी
              प्राईमरी स्कूल में हुई जिसमे उनका नाम महेश शंकर शर्मा लिखा गया।
            </p>
            <p className="font-Devnagri italic mt-3">
              दास का पहला एक दिवसीय प्रवचन सर्दीय नवरात्र २०१४ को हनुमान चालीसा
              पर नील कंठ महादेव मंदिर बिशनपुरा नॉएडा में हुआ इसी क्रम में वैदिक
              सनातन भक्ति का प्रचार परशार करने लगा परन्तु किसी से भी आज तक एक भी
              रुपया पैसा स्वीकार नहीं किया।
            </p>
          </div>
          <div className="mt-4">
            <Link
              to="/about"
              type="button"
              className="font-Philosopher focus:outline-none text-white bg-main hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
            >
              Know More
            </Link>
          </div>
        </div>
        <div className="rounded-lg w-full md:w-2/5">
          <img
            src={IMG1}
            className="img-fluid rounded-lg outline-offset-4 outline-dotted"
            alt="pandit maheshdas"
          />
        </div>
      </div>
    </section>
  );
}
