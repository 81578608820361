import { Link } from "react-router-dom";
import SpecialQuotes from "./SpecialQuotes";

export default function ExtraSection() {
  return (
    <section className="my-8 px-8">
      <div className="flex flex-wrap-reverse md:flex-wrap justify-between">
        <div className="bg-cardBackground rounded-lg p-3 outline-offset-4 outline-dotted">
          <div className="flex flex-col">
            <img
              src={require("../assets/Arth Panchak Siddhant.jpg")}
              className="img-fluid mb-4 "
              alt="Arth Panchak Siddhant"
            />
            <div className="flex">
              <Link
                to="/books"
                type="button"
                className="font-Philosopher mx-auto focus:outline-none text-white bg-main hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-bold rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900"
              >
                Get Book
              </Link>
            </div>
          </div>
        </div>
        <div className="flex-1 flex-col my-8 md:my-0 md:ml-8">
          <div className="bg-cardBackground md:h-2/5 rounded-lg p-3 outline-offset-4 outline-dotted">
            <div className="flex flex-col">
              <div>
                <h3 className="text-2xl text-center font-semibold font-Philosopher italic">
                  5 दिव्य विचार <br /> (5 divine thoughts)
                </h3>
                <div className="text-lg my-10 px-3 block md:flex justify-between">
                  <span>१. नियम (Rules)</span>
                  <br />
                  <span> २. कृपा (Grace)</span>
                  <br />
                  <span>३.तत्त्व ज्ञान (Philosophy)</span>
                  <br />
                  <span>४. सेवा (Service) </span>
                  <br />
                  <span>५. तीर्थ (Pilgrimage)</span>
                </div>
              </div>
              <div className="flex">
                <Link
                  to="/5-divine-thoughts"
                  type="button"
                  className="font-Philosopher mx-auto focus:outline-none text-white bg-main hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-bold rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900"
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>

          <div className="bg-cardBackground mt-8 rounded-lg p-3 outline-offset-4 outline-dotted">
            <div className="flex flex-col">
              <div>
                <h3 className="text-2xl text-center font-semibold font-Philosopher italic">
                  विजयते श्रीकृष्ण-संकीर्तनम
                </h3>
                <p className="text-lg text-justify px-3 md:px-10">प्रिय साधको,</p>
                <p className="text-lg my-8 text-justify md:text-center px-3 md:px-10">
                  कलियुग में केवल हरिनाम, हरिनाम और हरिनाम से ही उद्धार हो सकता
                  है| हरिनाम के अलावा कलियुग में उद्धार का अन्य कोई भी उपाय नहीं
                  है! नहीं है! नहीं है!
                </p>
                <SpecialQuotes
                  nosvg
                  small
                  data={{
                    stanza1: "हरेर्नाम हरेर्नाम हरेर्नामैव केवलं।",
                    stanza2: "कलौ नास्त्यैव नास्त्यैव नास्त्यैव गतिरन्यथा।।",
                  }}
                />
              </div>
              <div className="flex">
                <Link
                  to="/sandesh"
                  type="button"
                  className="font-Philosopher mx-auto focus:outline-none text-white bg-main hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-bold rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900"
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
