import { useEffect } from "react";

export default function Header() {
  const pathname = window.location.pathname;
  useEffect(() => {
    let navbar = document.getElementById("navbar");
    let navHeight = navbar.clientHeight;
    let main = document.getElementById("main");
    main.style.marginTop = `${navHeight}px`;
  }, []);

  function openMenu() {
    const elem = document.getElementById("navbar-sticky");
    if (elem.style.display !== "none") {
      elem.style.display = "none";
    } else {
      elem.style.display = "block";
    }
  }
  return (
    <nav
      id="navbar"
      className="px-2 sm:px-4 py-2.5 bg-main fixed w-full z-20 top-0 left-0 border-b border-main"
    >
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <span className="self-center text-2xl font-semibold font-Philosopher whitespace-nowrap text-black">
              Shri Krishna Sankirtan
            </span>
          </a>
        </div>
        <div className="flex md:order-2">
          <button
            onClick={() => openMenu()}
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul className="flex flex-col mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <a
                href="/"
                className={"block font-bold py-2 pl-3 pr-4 md:p-0 " + 
                `${pathname === '/'? "text-white " : "md:hover:text-white"}`}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/about"
                className={"block font-bold py-2 pl-3 pr-4 md:p-0 " + 
                `${pathname === '/about'? "text-white " : "md:hover:text-white"}`}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/sandesh"
                className={"block font-bold py-2 pl-3 pr-4 md:p-0 " + 
                `${pathname === '/sandesh'? "text-white " : "md:hover:text-white"}`}
              >
                Sandesh
              </a>
            </li>
            <li>
              <a
                href="/5-divine-thoughts"
                className={"block font-bold py-2 pl-3 pr-4 md:p-0 " + 
                `${pathname === '/5-divine-thoughts'? "text-white " : "md:hover:text-white"}`}
              >
                5 Divine Thoughts
              </a>
            </li>
            <li>
              <a
                href="/books"
                className={"block font-bold py-2 pl-3 pr-4 md:p-0 " + 
                `${pathname === '/books'? "text-white " : "md:hover:text-white"}`}
              >
                Books
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className={"block font-bold py-2 pl-3 pr-4 md:p-0 " + 
                `${pathname === '/contact'? "text-white " : "md:hover:text-white"}`}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
