import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SpecialQuotes from "../Components/SpecialQuotes";

export default function Books() {
  return (
    <div>
      <Header />
      <main id="main" className="main min-height">
        <section className="p-3">
          <div className="w-full border-b mb-5 pt-6">
            <div className="mx-auto px-8">
              <div className="mb-6 text-2xl font-bold text-[#333333]">
                <span>Books</span>
              </div>
            </div>
          </div>
          <div className="flex-1 bg-cardBackground rounded-lg p-3 m-3">
            <h2 className="font-Devnagri leading-normal text-heads text-3xl text-center font-semibold">
              अर्थपंचक सिद्धांत
            </h2>
            <div className="mt-8 mx-2 md:mx-8 leading-relaxed md:leading-loose text-justify italic text-base font-Philosopher">
              यहाँ पर लक्ष्मण जी ने प्रभु से अर्थपंचक सिद्धान्त के पाँच प्रश्न
              किये हैं जिनमें माया का प्रश्न विरोधी स्वरूप, ज्ञान, वैराग्य, साधन
              भक्ति का प्रश्न उपाय स्वरूप, ईश्वर और जीव का भेद परस्वरूप और
              स्वस्वरूप है वही भगवत चरण में रति अर्थात प्रेमलक्षणा भक्ति है जो
              शोक, मोह, भ्रम की समाप्ति के फल स्वरूप है।श्री राम ने भी इसीलिए
              क्रम से पहले माया का विवेचन, फिर ज्ञान, वैराग्य का विवेचन पुनः
              ईश्वर, जीव का भेद का विवेचन और पुनः प्रेमलक्षणा भक्ति का विवेचन
              किया। अर्थात् परस्वरूप, स्वस्वरूप, उपाय स्वरूप, फल स्वरूप और
              विरोधी स्वरूप यही अर्थपंचक है ।
            </div>
            <div className="flex mx-8">
              <div className="flex flex-col-reverse md:flex-row w-full justify-between py-5">
                <div className="w-full md:w-3/5">
                  <SpecialQuotes
                    nosvg
                    data={{
                      stanza1:
                        "थोरेहि महँ सब कहउँ बुझाई। सुनहु तात मन मति चित लाई।।",
                    }}
                  />
                  <div className="my-8 text-xl mx-2 md:mx-8 leading-relaxed md:leading-10 text-justify italic font-Philosopher">
                    1. माया क्या है? <br /> 2. ज्ञान क्या है? <br />
                    3. वैराग्य क्या है?
                    <br /> 4. ईश्वर और जीव में क्या भेद है? <br />
                    5. भक्ति क्या है?
                  </div>
                  <a
                    href="https://drive.google.com/file/d/125JxLyD4GQ94k5rvB1oI3O635ioIGSVl/view"
                    type="button"
                    className="font-Philosopher focus:outline-none text-white bg-main hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
                  >
                    Get Book
                  </a>
                </div>
                <div>
                  <img
                    className="outline-offset-4 outline-dotted"
                    alt="Artha Panchak Siddhanta"
                    src={require("../assets/Arth Panchak Siddhant.jpg")}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
