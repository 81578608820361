
import { Routes, Route } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import Books from "./Pages/Books";
import Contact from "./Pages/Contact";
import DivineThoughts from "./Pages/DivineThoughts";
import Home from "./Pages/Home";
import Message from "./Pages/Message";
import PageNotFound from "./Pages/PageNotFound";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/about" element={<AboutUs />}/>
        <Route exact path="/books" element={<Books />}/>
        <Route exact path="/sandesh" element={<Message />}/>
        <Route exact path="/contact" element={<Contact />}/>
        <Route exact path="/5-divine-thoughts" element={<DivineThoughts />}/>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}
export default App;