export default function AboutSectionMaker(props) {
  return (
    <section className="my-7 md:my-3 px-8">
      <div
        className={
          "md:flex" +
          (props.position === "start" ? " flex-row" : " flex-row-reverse")
        }
      >
        <div className="md:py-5 w-full md:w-2/6">
          {props.img ? (
            <div className="flex relative justify-center items-center w-full z-10">
              <img
                className="outline-offset-4 outline-dotted"
                src={props.img}
                alt=""
              />
            </div>
          ) : (
            <div className="animate-pulse">
              <div className="flex justify-center items-center w-full h-48 bg-gray-300 sm:w-96"></div>
            </div>
          )}
        </div>
        <div className="italic w-full md:w-10/12 bg-cardBackground rounded-lg p-3 md:p-10">
          {props.heading ? (
            <h2 className="font-Devnagri leading-normal text-heads text-3xl font-semibold">
              {props.heading}
            </h2>
          ) : null}
          {props.subheading ? (
            <div className="mt-5 leading-normal text-lg text-heads font-Philosopher flex items-center md:mr-8">
              {props.subheading}
              <span className="flex-1 border-dashed border-zinc-700 border-t-2 ml-4"></span>
            </div>
          ) : null}
          {props.para1 ? (
            <div className="mt-8 leading-relaxed md:leading-loose text-justify text-base font-Devnagri md:pr-8">
              {props.para1}
            </div>
          ) : null}
          {props.paraHtml ? (
            <div
              className="mt-3 leading-relaxed md:leading-loose text-justify text-base md:pr-8"
              dangerouslySetInnerHTML={{ __html: props.paraHtml }}
            ></div>
          ) : null}
          {props.para2 ? (
            <div className="mt-3 leading-relaxed md:leading-loose text-justify text-base font-Devnagri md:pr-8">
              {props.para2}
            </div>
          ) : null}
          {props.quote ? (
            <div className="mt-5 text-center leading-relaxed md:leading-loose text-xl md:pr-8">
              {props.quote}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}
