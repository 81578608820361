import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function PageNotFound() {
  return (
    <div>
      <Header />
      <main id="main" className="main min-height">
        <div className="text-center text-5xl">page not found</div>
        <div className="flex">
          <div className="mt-4 mx-auto">
            <Link
              to="/"
              type="button"
              className="font-Philosopher focus:outline-none text-white bg-main hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
            >
              Back Home
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
