import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function HeroHome() {
  return (
    <section className="">
      <Carousel showThumbs={false} infiniteLoop autoPlay interval={6000} transitionTime={800} showArrows={false}>
        <div>
          <img alt="" src={require("../assets/banner_2.jpg")} />
        </div>
        <div>
          <img alt="" src={require("../assets/banner_1.jpg")} />
        </div>
        <div>
          <img alt="" src={require("../assets/banner_3.jpg")} />
        </div>
      </Carousel>
    </section>
  );
}
