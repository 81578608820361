import AboutSectionMaker from "../Components/AboutSectionMaker";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import IMG1 from "../assets/about_1.jpg";
import IMG2 from "../assets/about_2.jpg";
import IMG3 from "../assets/about_3.jpg";
import IMG4 from "../assets/pooja_noida.jpg";

const Data = {
  QuoteAtma: {
    stanza1: "आत्मा मेरी गोपी है , परमात्मा श्री कृष्ण।",
    stanza2: "संकीर्तन मेरा प्राण है , सेवा मेरा कर्त्तव्य।।",
    writer: "पं. महेश दास",
  },
  Para1:
    "दास का जन्म उत्तरप्रदेश  के जिला हापुड़ के ग्राम शिव नगरी बागड़पुर में एक ब्राह्मण परिवार में दिन रविवार १४ जून  १९८७ को प्रातःकाल हुआ। दास की माता का नाम ओमवती देवी तथा पिता का नाम श्री देवेन्द्र प्रशाद शर्मा है।  दास की प्रारंभिक शिक्षा अपने ग्राम में ही सरकारी प्राईमरी  स्कूल में हुई जिसमे उनका नाम महेश शंकर शर्मा लिखा गया। ",
  Para2:
    "बाद में हापुड़ से स्नातक की डिˈग्री प्राप्त की और गाजियाबाद से एम बी ए <span style=font-family:'Philosopher'>(Master of Business Administration)</span> की पढ़ाई पूरी की। पढ़ाई  के बाद दास ने  प्राइवेट कंपनी में मैनेजर की नौकरी की। दास का विवाह ०७ जुलाई २०११ को धर्मपत्नी विनीता देवी से हुआ।",
  Para3:
    "दास ने जन्म से ही अपने बाबा को गॉव बालो के साथ गीता पर प्रवचन करते देखा। कुछ बड़े होने पर पिता को रामायण गीता आदि पर गॉव बालो के साथ प्रवचन करते सुना। एक दिन दास को राधा–कृष्ण के दर्शन की तीव्र अभिलाशा हुई। दास बिना बताये  बिना रुपया पेशा कपडे आदि के ही वृन्दावन को निकल पड़े। वहां दास का परिचय जगद्गुरु श्री कृपालु जी महाराज से हुआ। उन्होंने हमारी परीक्षा ली तदुपरांत उन्होंन भक्ति देवी से कहा की जाओ हमने  इन्हे अपना शिष्य मान  लिया है इन्हे ज्ञान करा दो। बस फिर क्या था गुरु देव की कृपा से कुछ बर्ष के भीतर समस्त वेद–शास्त्रों का ज्ञान हो गया व् दास का  जीवन गुरु कृपा से धन्य हो गया।",
  quote2: '"गुरु: कृपालुर्मम शरणम्, वन्देऽहं सदगुरु चरणम् "',
  quote3: '"गुर पितु मातु महेस भवानी। प्रनवउँ दीनबंधु दिन दानी"',
  Para4:
    "दास बचपन से ही रविवार में छपकौली शिव मंदिर दर्शन करने जाता था एक दिन अनायाश ही  शंकर भगवान का साक्षात्कार दास को  हुआ।  भगवान शिव द्वारा वरदान में सांसारिक वस्तुओ के मांगने का आग्रह करने पर दास ने अपने ईष्ट के दरशन की अभिलाषा व्यक्त की तव भगवान शिव ने प्रतिक्षा करने और इसी जन्म में मनोरथ सिद्ध होने का आश्वाशन दिया।",
  Para5:
    "दास के जीवन में दूसरा चमत्कार पत्नी के साथ हुआ।  ये  शरदीय नवरात्र स्कंदमाता की  घटना है जब सुबह ४ बजे पूजा की कुछ सामग्री ना मिलने के कारण दास को बड़ा दुःख हुआ की पूजा कैसे होगी और दास अपनी पत्नी पर भी खीज पड़ा तब साक्षात् देवी माँ ने प्रकट होकर वह सामग्री दास का नाम लेकर पत्नी को प्रदान की यह देखकर हम दोनों की आँखों से आंशुओ की धारा बहने लगी और पूजा संपन्न हुई ।  उस पुरे दिन हम दोनों की आंखे आँशु से भरी रही । माता का आशीर्वाद समंझकर दास ने उसका एक भाग आज भी संजोकर रखा हुआ है।",
  Para6:
    "एक दिन परम् वैराग्य जागा तव दास सयाने बुलंदशहर जिला दिव्य भारती के आश्रम जा पहुंचा और कुछ दिन तक वंही सेवारत रहा और गृहस्थ पालन के साथ साथ भक्ति के प्रचार परशार का आशीर्वाद देकर लोटा दिया।",
  Para7:
    "दास का पहला एक दिवसीय प्रवचन सर्दीय नवरात्र  २०१४  को हनुमान चालीसा पर  नील कंठ महादेव मंदिर बिशनपुरा नॉएडा में हुआ  इसी क्रम में वैदिक सनातन भक्ति का प्रचार परशार करने लगा परन्तु  किसी से भी आज तक एक भी रुपया पैसा स्वीकार नहीं किया।",
};
export default function AboutUs() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Header />
      <main id="main" className="main min-height">
        {/* <HeroHome /> */}
        {/* <div className="flex pt-5 pb-3 items-center text-center">
          <span className="flex-1 border-dashed border-zinc-700 border-t-2 mx-4"></span>
          <h1 className="font-Philosopher text-4xl font-semibold text-heads">
            पं. महेश दास
          </h1>
          <span className="flex-1 border-dashed border-zinc-700 border-t-2 mx-4"></span>
        </div> */}
        {/* <SpecialQuotes data={Data.QuoteAtma} /> */}
        <div className="w-full border-b mb-5 pt-6">
            <div className="mx-auto px-8">
              <div className="mb-6 text-2xl font-bold text-[#333333]">
                <span>About</span>
              </div>
            </div>
          </div>
        <h1 className="text-xl my-3 text-center">
          <span className="italic font-bold font-Philosopher">
            पं. महेश दास&nbsp;
          </span>
          <span className="font-bold font-Philosopher">( Pt. Mahesh Das )</span>
        </h1>
        <AboutSectionMaker
          heading="जीवन यात्रा"
          subheading="महेश शंकर शर्मा से पं. महेश दास"
          img={IMG1}
          para1={Data.Para1}
          paraHtml={Data.Para2}
          para2={Data.Para6}
          position="start"
        />
        <AboutSectionMaker
          heading="जगद्गुरु श्री कृपालु जी महाराज की कृपा"
          subheading="महेश शंकर शर्मा से पं. महेश दास"
          img={IMG2}
          para1={Data.Para3}
          quote={Data.quote2}
          position="end"
        />
        <AboutSectionMaker
          heading="भगवान से साक्षात्कार"
          subheading="महेश शंकर शर्मा से पं. महेश दास"
          img={IMG3}
          para1={Data.Para4}
          para2={Data.Para5}
          quote={Data.quote3}
          position="start"
        />
        <AboutSectionMaker
          heading="वैदिक सनातन भक्ति का प्रचार परशार"
          subheading="पं. महेश दास द्वारा किया गया प्रथम कार्यक्रम"
          img={IMG4}
          para1={Data.Para7}
          position="end"
        />
      </main>
      <Footer />
    </div>
  );
}
