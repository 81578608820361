export default function Footer() {
  return (
    <footer className="w-full bg-white">
      <div className="py-6 px-4 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between">
        <span className="text-sm font-Philosopher text-gray-500 dark:text-gray-300 sm:text-center">
          © 2022 <a href="/">Shri Krishna Sankirtan</a>. All Rights Reserved. Designed & Developed By <a href="/">Vaibhav Sharma</a>
        </span>
        <div className="flex mt-4 space-x-6 justify-center md:mt-0">
          <a
            href="https://www.facebook.com/ShriKrishnaSankirtan"
            target="_blank"
            rel="noreferrer"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Facebook page</span>
          </a>
          <a
            href="https://wa.me/919625548978?text=Radhey%20Radhey"
            rel="noreferrer"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M 19.7215 3.3727 A 11.3883 11.3883 90 0 0 11.5913 0 C 5.2591 0 0.0986 5.1591 0.0928 11.4927 c 0 2.0286 0.5307 4.002 1.5326 5.7492 L 0 23.2 l 6.0958 -1.5979 a 11.5029 11.5029 90 0 0 5.4955 1.3992 h 0.0058 c 6.3336 0 11.4927 -5.1591 11.4985 -11.4985 A 11.4521 11.4521 90 0 0 19.72 3.3727 z M 11.5913 21.0555 a 9.5308 9.5308 90 0 1 -4.8662 -1.334 l -0.348 -0.2088 l -3.6163 0.9483 l 0.9657 -3.5278 l -0.2262 -0.3639 a 9.512 9.512 90 0 1 -1.4601 -5.0822 c 0 -5.2577 4.2876 -9.5468 9.557 -9.5468 a 9.512 9.512 90 0 1 6.757 2.7999 a 9.5076 9.5076 90 0 1 2.7956 6.757 c -0.0058 5.2765 -4.2934 9.5584 -9.5584 9.5584 z m 5.2418 -7.1543 c -0.2857 -0.1436 -1.6965 -0.8381 -1.9618 -0.9367 c -0.2639 -0.0943 -0.4567 -0.1436 -0.6452 0.1436 c -0.1928 0.2857 -0.7439 0.9367 -0.9092 1.1238 c -0.1653 0.1928 -0.3364 0.2146 -0.6235 0.0725 c -0.2857 -0.145 -1.2122 -0.4466 -2.3084 -1.4283 c -0.8555 -0.7612 -1.4283 -1.7038 -1.5993 -1.9894 c -0.1653 -0.2871 -0.0159 -0.4408 0.1276 -0.5844 c 0.1261 -0.1276 0.2857 -0.3364 0.4292 -0.5017 c 0.145 -0.1653 0.1928 -0.2871 0.2871 -0.4785 c 0.0943 -0.1943 0.0493 -0.3596 -0.0217 -0.5031 c -0.0725 -0.1436 -0.6452 -1.5602 -0.8874 -2.1315 c -0.232 -0.5641 -0.4683 -0.4858 -0.6452 -0.493 c -0.1653 -0.0101 -0.3581 -0.0101 -0.551 -0.0101 a 1.057 1.057 90 0 0 -0.7671 0.3581 c -0.2639 0.2871 -1.0019 0.9817 -1.0019 2.3983 c 0 1.4166 1.0295 2.7782 1.1745 2.971 c 0.1421 0.1928 2.0213 3.0914 4.9053 4.3384 c 0.6815 0.2972 1.218 0.4727 1.637 0.6061 c 0.6887 0.2204 1.3108 0.187 1.8067 0.116 c 0.551 -0.0841 1.698 -0.696 1.9401 -1.3673 c 0.2378 -0.6728 0.2378 -1.247 0.1653 -1.3673 c -0.0711 -0.1218 -0.2639 -0.1928 -0.551 -0.3364 z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Whatsapp page</span>
          </a>
          <a
            href="https://www.youtube.com/@shrikrishnasankirtan"
            target="_blank"
            rel="noreferrer"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M 11.6739 2.8986 h 0.129 c 1.1919 0.0043 7.2311 0.0479 8.8595 0.4858 a 2.9145 2.9145 90 0 1 2.0518 2.059 c 0.1464 0.551 0.2494 1.2803 0.319 2.0329 l 0.0145 0.1508 l 0.0319 0.377 l 0.0116 0.1508 c 0.0943 1.3253 0.1058 2.5665 0.1073 2.8377 v 0.1087 c -0.0014 0.2813 -0.0145 1.6066 -0.1189 2.987 l -0.0116 0.1522 l -0.013 0.1508 c -0.0725 0.8294 -0.1798 1.653 -0.3407 2.2591 a 2.9101 2.9101 90 0 1 -2.0518 2.059 c -1.682 0.4524 -8.075 0.4843 -8.961 0.4858 h -0.2059 c -0.4481 0 -2.3011 -0.0087 -4.2442 -0.0754 l -0.2465 -0.0087 l -0.1261 -0.0058 l -0.248 -0.0101 l -0.248 -0.0101 c -1.6095 -0.0711 -3.1421 -0.1856 -3.8483 -0.377 a 2.9101 2.9101 90 0 1 -2.0518 -2.0575 c -0.161 -0.6046 -0.2682 -1.4297 -0.3407 -2.2591 L 0.1305 14.239 l -0.0116 -0.1508 A 45.53 45.53 90 0 1 0 11.136 v -0.1783 c 0.0029 -0.3117 0.0145 -1.3891 0.0928 -2.5781 l 0.0101 -0.1493 l 0.0043 -0.0754 l 0.0116 -0.1508 l 0.0319 -0.377 l 0.0145 -0.1508 c 0.0696 -0.7526 0.1725 -1.4833 0.319 -2.0329 a 2.9101 2.9101 90 0 1 2.0518 -2.059 c 0.7061 -0.1885 2.2388 -0.3045 3.8483 -0.377 l 0.2465 -0.0101 l 0.2494 -0.0087 l 0.1247 -0.0043 l 0.248 -0.0101 A 144.6926 144.6926 90 0 1 11.3941 2.9 h 0.2798 z M 9.28 7.553 v 6.9861 l 6.0276 -3.4916 L 9.28 7.553 z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Youtube page</span>
          </a>
          <a
            href="https://www.instagram.com/shrikrishnasankirtan"
            target="_blank"
            rel="noreferrer"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Instagram page</span>
          </a>
          <a
            href="https://twitter.com/PtMaheshDas"
            target="_blank"
            rel="noreferrer"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
            <span className="sr-only">Twitter page</span>
          </a>
        </div>
      </div>
    </footer>
  );
}
