import SpecialQuotes from "./SpecialQuotes";

export default function Mission() {
  return (
    <section className="px-3">
      <div className="flex-1 bg-message bg-center bg-no-repeat bg-cover">
        <div className="py-10 px-5 md:px-40">
          <h2 className="leading-normal text-heads text-3xl text-center font-semibold">
            विजयते श्रीकृष्ण-संकीर्तनम
          </h2>
          <p className="mt-3 font-Philosopher text-lg text-justify">
            Our mission to spread the devotion (Divine love & service to
            God). Because by divine love & selfless service, we find the
            ultimate aim of human life, beloved God, true peace and divine
            height.
          </p>
          <SpecialQuotes
            nosvg
            data={{
              stanza1: "The soul is my Gopi, the Supreme Soul Shri Krishna.",
              stanza2: " Sankirtan is my life, service is my duty.",
              writer: "- Pt. Mahesh Das",
            }}
          />
          <p className="mt-3 font-Philosopher text-lg text-justify">
            हमारा मिशन भक्ति (दिव्य प्रेम और भगवान की सेवा) का प्रसार करना
            है। क्योंकि दिव्य प्रेम और निःस्वार्थ सेवा से हम मानव जीवन का
            अंतिम लक्ष्य, प्यारे भगवान, सच्ची शांति और दिव्य ऊंचाई पाते
            हैं।
          </p>
          <SpecialQuotes
            nosvg
            data={{
              stanza1: "आत्मा मेरी गोपी है , परमात्मा श्री कृष्ण।",
              stanza2: " संकीर्तन मेरा प्राण है , सेवा मेरा कर्त्तव्य।। ",
              writer: "- पं. महेश दास",
            }}
          />
        </div>
      </div>
    </section>
  );
}
