export default function SpecialQuotes(props) {
  const Quote = props.data;
  return (
    <section className="my-7 px-3 ">
      <figure className="mx-auto max-w-screen-md text-center">
        {!props.nosvg ? (
          <svg
            aria-hidden="true"
            className="mx-auto mb-3 w-12 h-12 text-gray-400 dark:text-gray-600"
            viewBox="0 0 24 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
              fill="currentColor"
            />
          </svg>
        ) : null}
        <blockquote className="font-Philosopher">
          {Quote.stanza1 ? (
            <p
              className={
                "italic font-medium text-gray-900 " +
                `${props.small ? "text-lg md:text-xl" : "text-xl md:text-2xl"}`
              }
            >
              {Quote.stanza1}
            </p>
          ) : null}
          {Quote.stanza2 ? (
            <p
              className={
                `italic font-medium text-gray-900 ` +
                `${props.small ? "text-lg md:text-xl" : "text-xl md:text-2xl"}`
              }
            >
              {Quote.stanza2}
            </p>
          ) : null}
        </blockquote>
        {Quote.writer ? (
          <figcaption className="flex justify-center items-center mt-6 space-x-3">
            {!props.nosvg ? (
              <img
                className="w-6 h-6 rounded-full"
                src={require("../assets/quote.jpg")}
                alt="profile"
              />
            ) : null}
            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
              <cite className="pr-3 font-medium text-gray-900">
                {Quote.writer}
              </cite>
            </div>
          </figcaption>
        ) : null}
      </figure>
    </section>
  );
}
